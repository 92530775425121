<template>
  <p class="consigne">{{ etape }} - Remplacez le(s) verbe(s) par un verbe de sens proche.</p>

  <div class="container">
    <span v-for="mot in listeMotsRegroupes()" :key="mot">
      {{ motAffiche(mot) }}
    </span>
  </div>

  <div v-for="(verbe, indice) in verbesSelectionnes" :key="indice" class="listeVerbes">
    <v-col>
      <v-text-field :label="verbe" v-model="verbesValues[verbe]" density="compact" variant="solo"
        :loading="!verbesValues[verbe]">
        <!-- <template v-slot:loader>
          <v-progress-linear indeterminate color="purple" :active="!verbesValues[verbe]"></v-progress-linear>
        </template> -->
      </v-text-field>
    </v-col>
  </div>
</template>

<script>
export default {
  props: ["etape"],

  emits: ['updateVerbesValues'],


  inject: ["verbesSelectionnes", "listeMots", "listeMotsRegroupes"],

  data()
  {
    return {
      verbesValues: {},
      nouvellePhrase: []
    };
  },

  watch: {
    verbesValues: {
      handler(newValue)
      {
        this.$emit('updateVerbesValues', newValue)
      },
      deep: true // à utiliser quand on veut surveiller un objet, sinon on ne passe jamais dans le watch
    }
  },

  methods: {
    motAffiche(mot)
    {
      if (mot === " ")
      {
        return "\u00a0";
      } else if (this.verbesSelectionnes.includes(mot))
      {
        return this.verbesValues[mot] || "____"; // quand l'utilisateur n'a pas encore rempli le champ, la valeur de la clef est 'null'
      } else
      {
        return mot;
      }
    },

    motDansListe(mot, liste)
    {
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < liste.length; i++)
      {
        if (liste[i].split(/\s+/).includes(mot))
        {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    calculNouvellePhrase()
    {
      this.nouvellePhrase = this.listeMotsRegroupes.map(mot => this.motAffiche(mot));
    }
  },
};
</script>

<style scoped>
.container {
  max-width: 100rem;
  margin: 0rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 2px solid #ccc;
  border-radius: 12px;
}

/* .verbe {
  color: blue;
  margin-right: 1rem;
} */

input {
  position: absolute;
  margin-left: 15rem;
}

.listeVerbes {
  display: flex;
  padding: 1px;
}

input[type="text"] {
  border: 1px solid #ccc;
  padding: 0px;
  background-color: white;
  color: black;
}

.v-text-field {
  width: 20%;
}

.v-text-field :deep(label) {
  color: blue;
  /* background-color: orange; */
  opacity: 100;
  transition: transform 0.2s ease;
}
</style>
