<template>
  <h1>Classes Grammaticales</h1>

  <v-hover v-slot:default="{ isHovering, props }">
    <v-card class="container" v-if="etape1termine === false" v-bind="props" :elevation="isHovering ? 8 : 4">
      <p class="phrasePrincipale" @mouseup="getHighlightedText">
        <span v-for="(mot, indice) in listeMotsVerbesRegroupes" :key="indice" :class="{
          verbe: motSelectionneVerbesRegroupes(mot),
        }">
          {{ mot }}
        </span>
      </p>
    </v-card>
  </v-hover>

  <v-hover v-slot:default="{ isHovering, props }">
    <v-card class="container" v-if="etape1termine === true" v-bind="props" :elevation="isHovering ? 8 : 4">
      <p class="phrasePrincipale" @mouseup="getHighlightedText">
        <span v-for="(mot, indice) in listeMotsVerbesRegroupes" :key="indice" :class="{
          verbe: motSelectionneVerbesRegroupes(mot),
          verbePrincipal: estUnVerbePrincipal(mot),
          estCliquable: estCliquable(mot),
          nom: estUnNom(mot, indice),
          determinant: estUnDeterminant(mot, indice),
          adjectif: estUnAdjectif(mot, indice),
          pronom: estUnPronom(mot, indice),
          conjonctionCoord: estUneConjonctionCoord(mot, indice),
          conjonctionSub: estUneConjonctionSub(mot, indice),
          preposition: estUnePreposition(mot, indice),
          adverbe: estUnAdverbe(mot, indice)
        }" @click="selectionVerbePrincipal(mot)">
          {{ mot }}
        </span>
      </p>
    </v-card>
  </v-hover>

  <analyse-verbe @annuler="annulation" @annulerVerbePrincipal="annulationVerbePrincipal" @valider="validation"
    @confirmerEtape1="confirmationEtape1" @confirmerEtape2="confirmationEtape2" @finAnalyseVerbe="finAnalyseVerbe"
    v-if="partie === 'analyse-verbe'">
    <template v-slot:selection-verbe>
      <v-chip v-for="verbe in selection" :key="verbe" color="primary" class="ma-2" outlined>
        {{ verbe }}
      </v-chip>
    </template>
  </analyse-verbe>

  <analyse-classes-grammaticales @nomValidé="nomValide" @nomAnnulé="nomAnnule" @determinantValidé="determinantValide"
    @determinantAnnulé="determinantAnnule" @adjectifValidé="adjectifValide" @typeAdjectifValidé="typeAdjectifValide"
    @adjectifAnnulé="adjectifAnnule" @pronomValidé="pronomValide" @pronomAnnulé="pronomAnnule"
    @conjonctionCoordValidé="conjonctionCoordValide" @conjonctionCoordAnnulé="conjonctionCoordAnnule"
    @conjonctionSubValidé="conjonctionSubValide" @conjonctionSubAnnulé="conjonctionSubAnnule"
    @prepositionValidé="prepositionValide" @prepositionAnnulé="prepositionAnnule" @adverbeValidé="adverbeValide"
    @adverbeAnnulé="adverbeAnnule" v-if="partie === 'analyse-classes-grammaticales'">
  </analyse-classes-grammaticales>
</template>

<script>
import AnalyseVerbe from "./AnalyseVerbe/AnalyseVerbe.vue";
import AnalyseClassesGrammaticales from "./AnalyseClassesGrammaticales/AnalyseClassesGrammaticales.vue";
import Utility from '../mixins/utility.js';

export default {
  props: ["phrase"], // On reçoit la phrase en tant que prop à partir de App.vue

  mixins: [Utility],

  components: {
    AnalyseVerbe,
    AnalyseClassesGrammaticales,
  },

  provide()
  {
    return {
      verbesSelectionnes: this.selection,
      listeMots: this.listeMots,
      listeMotsRegroupes: (() => this.listeMotsVerbesRegroupes),
      getVerbePrincipal: () => this.verbePrincipal,
      motSelectionneVerbesRegroupes: this.motSelectionneVerbesRegroupes,
      getNomSelectionne: () => this.nomSelectionne,
      getDeterminantSelectionne: () => this.determinantSelectionne,
      getAdjectifSelectionne: () => this.adjectifSelectionne,
      getPronomSelectionne: () => this.pronomSelectionne,
      getConjonctionCoordSelectionne: () => this.conjonctionCoordSelectionne,
      getConjonctionSubSelectionne: () => this.conjonctionSubSelectionne,
      getPrepositionSelectionne: () => this.prepositionSelectionnee,
      getAdverbeSelectionne: () => this.adverbeSelectionne
    };
  },

  data()
  {
    return {
      selection: [],
      texteSelectionne: "",
      // listeMotsVerbesRegroupes: [],
      verbePrincipal: "",
      partie: "analyse-verbe",
      nomSelectionne: [],
      determinantSelectionne: [],
      adjectifSelectionne: [],
      pronomSelectionne: [],
      conjonctionCoordSelectionne: [],
      conjonctionSubSelectionne: [],
      prepositionSelectionnee: [],
      adverbeSelectionne: [],
      etape1termine: false,
      etape2termine: false,
    };
  },

  computed: {

    listeMots()
    {
      // Retourne une liste avec les différents mots de la phrase, avec les apostrophes, espaces et points comment éléments de la liste mis à part. Utilise les regex.

      return this.phrase.split(/(\s+|'|’|\.|,)/).filter(Boolean) // le .filter(Boolean) permet d'enlever le caractère vide que rajoute split à la fin. Le \. est nécessaire car le simple . a une signification particulière en regex
    },

    listeMotsVerbesRegroupes()
    {
      return this.regrouperVerbes(this.listeMots);
    }
  },

  methods: {
    getHighlightedText()
    {
      var selection = null;

      if (window.getSelection)
      {
        selection = window.getSelection();
      } else if (typeof document.selection != "undefined")
      {
        selection = document.selection;
      }

      if (selection.rangeCount > 0)
      {
        const range = selection.getRangeAt(0);
        this.texteSelectionne = range.toString();
      }
    },

    annulation()
    {
      this.selection = [];
    },

    annulationVerbePrincipal()
    {
      this.verbePrincipal = "";
    },

    validation()
    {
      this.selection.push(this.texteSelectionne.trim());
    },

    confirmationEtape1()
    {
      const listeVerbes = this.selection.map(verbe => (
        {
          mot: verbe,
          type: ""
        }
      ));

      this.$store.dispatch('initializeVerbesList', listeVerbes);

      this.etape1termine = true;
    },

    confirmationEtape2()
    {
      this.etape2termine = true;
    },

    finAnalyseVerbe()
    {
      this.partie = "analyse-classes-grammaticales";
    },

    motSelectionne(mot, indice)
    {
      // On traite le cas d'un espace entre un verbe composé
      if (mot === " ")
      {
        for (let i = 0; i < this.selection.length; i++)
        {
          if (
            this.selection[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          )
          {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot))
      {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.selection.length; i++)
      {
        if (this.selection[i].split(/\s+/).includes(mot))
        {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    longueurVerbeSelectionne(chaine)
    {
      const nombreVerbes = chaine.split(" ").length;

      return nombreVerbes + (nombreVerbes - 1);
    },

    regrouperVerbes(liste)
    {
      let nouvelleListe = [...liste];
      for (const verbe of this.selection)
      {
        // Séparer le verbe en mots
        let motsDuVerbe = this.chaineVersListe(verbe);
        let motsDuVerbeSansEspace = verbe.trim().split(/\s+/);

        // Trouver l'index de la première occurrence des mots dans la nouvelle liste
        let index = nouvelleListe.findIndex((mot) => motsDuVerbeSansEspace.includes(mot));

        // Si les mots sont trouvés dans la liste, les remplacer par le verbe complet
        if (index !== -1) // on teste si un mot du verbe a bien été trouvé
        {
          nouvelleListe.splice(
            index, // on commence à remplacer à partir de l'indice index
            motsDuVerbe.length, // on remplace ce nombe d'éléments dans la liste
            verbe
          );
        }
      }

      return nouvelleListe;
    },

    motSelectionneVerbesRegroupes(mot)
    {
      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot))
      {
        return false;
      }

      if (this.selection.includes(mot))
      {
        return true;
      }

      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    estUnVerbePrincipal(mot)
    {
      if (mot === this.verbePrincipal)
      {
        return true;
      }
      return false;
    },

    estCliquable(mot)
    {
      // Les mots ne sont pas cliquables si l'étape 1 n'est pas terminé ou que l'étape 2 est terminé
      if (!this.etape1termine || this.etape2termine)
      {
        return false;
      }

      // Seuls les verbes sont cliquables
      if (this.selection.includes(mot))
      {
        return true;
      }

      return false;
    },

    selectionVerbePrincipal(mot)
    {
      // Les mots ne sont pas interragibles si on est pas à l'étape 2 ou que le mot n'est pas un verbe
      if (
        !this.etape1termine ||
        this.etape2termine ||
        !this.selection.includes(mot)
      )
      {
        return null;
      }

      this.verbePrincipal = mot;
    },

    estUnNom(_, indice)
    {
      for (let i = 0; i < this.nomSelectionne.length; i++)
      {
        let nomListe = this.chaineVersListe(this.nomSelectionne[i]);
        if (this.motInclusDansGroupe(this.listeMotsVerbesRegroupes, indice, nomListe))
        {
          return true;
        }
      }

      return false;
    },

    nomValide(nomSelectionne)
    {
      this.nomSelectionne.push(nomSelectionne);
    },

    nomAnnule(nomSelectionne)
    {
      this.nomSelectionne = this.nomSelectionne.filter(
        (element) => element != nomSelectionne
      );
    },

    determinantValide(determinantSelectionne)
    {
      this.determinantSelectionne.push(determinantSelectionne);
    },

    determinantAnnule(determinantSelectionne)
    {
      this.determinantSelectionne = this.determinantSelectionne.filter(
        (element) => element != determinantSelectionne
      );
    },

    estUnDeterminant(mot, indice)
    {
      // On traite le cas d'un espace entre un determinant composé
      if (mot === " ")
      {
        for (let i = 0; i < this.determinantSelectionne.length; i++)
        {
          if (
            this.determinantSelectionne[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          )
          {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot))
      {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.determinantSelectionne.length; i++)
      {
        if (this.determinantSelectionne[i].split(/\s+/).includes(mot))
        {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    adjectifValide(adjectifSelectionne)
    {
      const adjectifObject = {
        motAdjectif: adjectifSelectionne,
        type: null
      };

      this.adjectifSelectionne.push(adjectifObject);
    },

    adjectifAnnule(adjectifSelectionne)
    {
      this.adjectifSelectionne = this.adjectifSelectionne.filter(
        (element) => element.motAdjectif != adjectifSelectionne
      );
    },

    typeAdjectifValide(adjectifSelectionne, adjectifQualifiant)
    {
      this.adjectifSelectionne = this.adjectifSelectionne.map(adjectif =>
      {
        if (adjectif.motAdjectif == adjectifSelectionne)
        {
          return { ...adjectif, type: adjectifQualifiant };
        }
        return adjectif;
      }
      )
    },

    estUnAdjectif(mot, indice)
    {
      // On traite le cas d'un espace entre un determinant composé
      if (mot === " ")
      {
        for (let i = 0; i < this.determinantSelectionne.length; i++)
        {
          if (
            this.determinantSelectionne[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          )
          {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot))
      {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.adjectifSelectionne.length; i++)
      {
        if (this.adjectifSelectionne[i].motAdjectif.split(/\s+/).includes(mot))
        {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    pronomValide(pronomSelectionne)
    {
      this.pronomSelectionne.push(pronomSelectionne);
    },

    pronomAnnule(pronomSelectionne)
    {
      this.pronomSelectionne = this.pronomSelectionne.filter(
        (element) => element != pronomSelectionne
      );
    },

    estUnPronom(mot, indice)
    {
      // On traite le cas d'un espace entre un determinant composé
      if (mot === " ")
      {
        for (let i = 0; i < this.determinantSelectionne.length; i++)
        {
          if (
            this.determinantSelectionne[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          )
          {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot))
      {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.pronomSelectionne.length; i++)
      {
        if (this.pronomSelectionne[i].split(/\s+/).includes(mot))
        {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    conjonctionCoordValide(conjonctionCoordSelectionne)
    {
      this.conjonctionCoordSelectionne.push(conjonctionCoordSelectionne);
    },

    conjonctionCoordAnnule(conjonctionCoordSelectionne)
    {
      this.conjonctionCoordSelectionne = this.conjonctionCoordSelectionne.filter(
        (element) => element != conjonctionCoordSelectionne
      );
    },

    estUneConjonctionCoord(mot, indice)
    {
      // On traite le cas d'un espace entre un determinant composé
      if (mot === " ")
      {
        for (let i = 0; i < this.determinantSelectionne.length; i++)
        {
          if (
            this.determinantSelectionne[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          )
          {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot))
      {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.conjonctionCoordSelectionne.length; i++)
      {
        if (this.conjonctionCoordSelectionne[i].split(/\s+/).includes(mot))
        {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    conjonctionSubValide(conjonctionSubSelectionne)
    {
      this.conjonctionSubSelectionne.push(conjonctionSubSelectionne);
    },

    conjonctionSubAnnule(conjonctionSubSelectionne)
    {
      this.conjonctionSubSelectionne = this.conjonctionSubSelectionne.filter(
        (element) => element != conjonctionSubSelectionne
      );
    },

    estUneConjonctionSub(mot, indice)
    {
      // On traite le cas d'un espace entre un determinant composé
      if (mot === " ")
      {
        for (let i = 0; i < this.determinantSelectionne.length; i++)
        {
          if (
            this.determinantSelectionne[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          )
          {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot))
      {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.conjonctionSubSelectionne.length; i++)
      {
        if (this.conjonctionSubSelectionne[i].split(/\s+/).includes(mot))
        {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    prepositionValide(prepositionSelectionnee)
    {
      this.prepositionSelectionnee.push(prepositionSelectionnee);
    },

    prepositionAnnule(prepositionSelectionnee)
    {
      this.prepositionSelectionnee = this.prepositionSelectionnee.filter(
        (element) => element != prepositionSelectionnee
      );
    },

    estUnePreposition(mot, indice)
    {
      // On traite le cas d'un espace entre un determinant composé
      if (mot === " ")
      {
        for (let i = 0; i < this.determinantSelectionne.length; i++)
        {
          if (
            this.determinantSelectionne[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          )
          {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot))
      {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.prepositionSelectionnee.length; i++)
      {
        if (this.prepositionSelectionnee[i].split(/\s+/).includes(mot))
        {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    adverbeValide(adverbeSelectionne)
    {
      this.adverbeSelectionne.push(adverbeSelectionne);
    },

    adverbeAnnule(adverbeSelectionne)
    {
      this.adverbeSelectionne = this.adverbeSelectionne.filter(
        (element) => element != adverbeSelectionne
      );
    },

    estUnAdverbe(mot, indice)
    {
      // On traite le cas d'un espace entre un determinant composé
      if (mot === " ")
      {
        for (let i = 0; i < this.determinantSelectionne.length; i++)
        {
          if (
            this.determinantSelectionne[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          )
          {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot))
      {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.adverbeSelectionne.length; i++)
      {
        if (this.adverbeSelectionne[i].split(/\s+/).includes(mot))
        {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },


  },
};
</script>

<style scoped>
h1 {
  margin-left: 1rem;
}

p {
  margin-left: 1rem;
}

.verbePrincipal {
  font-weight: bold;
}

.sujet {
  color: red;
}

.cod {
  color: green;
}

.coi {
  color: rgb(151, 108, 13);
}

.nom {
  color: rgb(155, 132, 5);
  display: inline-block;
  /* Assure que le cercle n'occupe que l'espace nécessaire autour du texte */
  padding: 0.5em;
  /* Ajoutez un espace intérieur pour dégager le texte du cercle */
  border: 2px solid rgb(155, 132, 5);
  /* Couleur de la bordure */
  border-radius: 50%;
  /* Border-radius à 50% crée un cercle */
}

.determinant {
  color: darksalmon;
  text-decoration: underline;
}

.adjectif {
  color: darkmagenta;
}

.pronom {
  color: red;
}

.conjonctionCoord {
  padding: 5px;
  margin: 0 40px;
  border-radius: 20px;
  border: 2px solid green;
  display: inline-block;
  position: relative;
}

.conjonctionCoord::before {
  content: "";
  position: absolute;
  /* Utilise une position absolue */
  left: -30px;
  /* Ajuste pour positionner les flèches à gauche */
  right: -30px;
  /* Ajuste pour positionner les flèches à droite */
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
  background:
    /* arrow */
    linear-gradient(to bottom right, green 49%, transparent 52%) bottom right/15px 50%,
    linear-gradient(to top right, green 49%, transparent 52%) top right/15px 50%,
    /* circle */
    /* radial-gradient(green 56%, transparent 60%) -2px 50%/20px 20px, */
    /* line */
    linear-gradient(green, green) right center/30px 2px,
    linear-gradient(green, green) left center /30px 2px,
    /* arrow */
    linear-gradient(to bottom left, green 49%, transparent 52%) bottom left/15px 50%,
    linear-gradient(to top left, green 49%, transparent 52%) top left/15px 50%;
  background-repeat: no-repeat;
  z-index: 1;
  /* Assure la visibilité des flèches */
}

.conjonctionSub {
  color: green;
  display: inline-block;
  /* Assure que le cercle n'occupe que l'espace nécessaire autour du texte */
  padding: 0.5em;
  /* Ajoutez un espace intérieur pour dégager le texte du cercle */
  border: 2px solid darkgreen;
  /* Couleur de la bordure */
  border-radius: 50%;
  /* Border-radius à 50% crée un cercle */
}


.preposition {
  text-decoration: underline double;
}

.adverbe {
  text-decoration: underline;
  text-decoration-style: wavy;
}

.container {
  max-width: 100rem;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  border: 2px solid #ccc;
  border-radius: 12px;
}

.phrasePrincipale {
  font-size: 200%;
}

.estCliquable {
  cursor: pointer;
}
</style>
